<template>
  <v-app app id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> | 
      <router-link to="/about">About</router-link><span v-if="$auth.isAuthenticated"> | </span>
      <router-link v-if="$auth.isAuthenticated" to="/admin">Admin</router-link><span v-if="$auth.isAuthenticated"> | </span>
      <router-link v-if="$auth.isAuthenticated" to="/profile">Profile</router-link>
    </div>
    <router-view />
  </v-app>
</template>

<script>
export default {}
</script>

<style lang="stylus">
  @import "./assets/stylus/main.styl";
</style>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
