/* eslint-disable */ 
import Vue from 'vue';
import i18n from './locales/index'
import store from './store'
import vuetify from './plugins/vuetify'
import App from './App.vue';
import router from './router';
import './plugins/http'
import './registerServiceWorker'


// Import the Auth0 configuration
import { domain, clientId } from '../auth_config.json';

// Import the plugin here
import { Auth0Plugin } from './auth';

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.config.productionTip = false;

Vue.http.interceptors.push((request, next) => {
  request.headers.set('x-api-key', 'qDgoMOgEt2aFukY9m32FI7zNRidHDi6n43KUKyTv')
  next(response => {})
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');